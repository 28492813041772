/** @jsxImportSource theme-ui */
import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";

// functions
import LocomotiveScroll from "locomotive-scroll";
import loopIt from "../../functions/loopIt";

// functional components
import SplitText from "../../functional-components/split-text";

// components
import ReactPlayer from "react-player/file";
import { StaticImage } from "gatsby-plugin-image";

import Seo from "../../utils/seo";
import TransButton from "../../components/trans-button";
import ArrowLink from "../../micro-components/arrow-link";

// media
import video1 from "../../video/video1.mp4";
import video2 from "../../video/video2.mp4";
import video3 from "../../video/video3.mp4";
import video4 from "../../video/video4.mp4";
import video5 from "../../video/video5.mp4";
import video6 from "../../video/video6.mp4";

// style
import "../../style/portfolio.scss";

gsap.registerPlugin(ScrollTrigger);

const Portfolio = () => {
  const scroll = useRef(null);
  const intro = useRef(null);
  const portfolio = useRef(null);
  const logo = useRef(null);

  // timelines
  const mainTl = useRef(null);

  // dynamic video srcs
  const scrs = [video1, video2, video3, video4, video5, video6];

  useEffect(() => {
    function callback(mutationList) {
      mutationList.forEach((mutation) => {
        switch (mutation.type) {
          case "attributes":
            switch (mutation.attributeName) {
              case "menu-status":
                const state = document
                  .querySelector("[menu-status]")
                  .getAttribute("menu-status");
                if (state === "close") {
                  scroll.current.start();
                }
                if (state === "open") {
                  scroll.current.stop();
                }
                break;
              case "main-visible-status":
                const visible = document
                  .querySelector("[main-visible-status]")
                  .getAttribute("main-visible-status");
                if (visible === "visible") {
                  mainTl.current.play();
                }
                break;
              default:
                break;
            }
            break;
          default:
            break;
        }
      });
    }
    // init Locomotive Sroll and ScrollTrigger
    scroll.current = new LocomotiveScroll({
      el: document.querySelector(".scroll-section"),
      smooth: true,
      multiplier: 0.75,
      firefoxMultiplier: 45,
      lerp: 0.1,
      smartphone: {
        breakpoint: 0,
        smooth: false,
      },
      tablet: {
        breakpoint: 0,
        smooth: false,
      },
    });
    // update ScrollTrigger each time LocomotiveScroll is updated
    scroll.current.on("scroll", ScrollTrigger.update);
    // Set ScrollTrigger to 'lister' to LocomotiveScroll instead of natural scroll
    ScrollTrigger.scrollerProxy(".scroll-section", {
      // this is need to define vertical scroll
      scrollTop(value) {
        return arguments.length
          ? scroll.current.scrollTo(value, 0, 0)
          : scroll.current.scroll.instance.scroll.y;
      },
      getBoundingClientRect() {
        return {
          top: 0,
          left: 0,
          width: window.innerWidth,
          height: window.innerHeight,
        };
      },
      // pin fixed elements to control mobile version of LocomotiveScroll
      pinType: document.querySelector(".scroll-section").style.transform
        ? "transform"
        : "fixed",
    });

    // Obsever section
    const targetNode1 = document.querySelector("[menu-status]"),
      targetNode2 = document.querySelector("[main-visible-status]");
    // observe menu for changes
    const observer1 = new MutationObserver(callback),
      observer2 = new MutationObserver(callback);
    // Start observing the target node for configured mutations
    observer1.observe(targetNode1, {
      attributes: true,
      attributeFilter: ["menu-status"],
      childList: false,
      subtree: false,
    });
    observer2.observe(targetNode2, {
      attributes: true,
      attributeFilter: ["main-visible-status"],
      childList: false,
      subtree: false,
    });

    // // PUT THERE ALL THE TIMELINES // //
    // define main timeline
    const qIntro = gsap.utils.selector(intro);
    mainTl.current = gsap.timeline({
      paused: true,
      defaults: {
        ease: "expo.out",
      },
    });
    mainTl.current
      .fromTo(
        qIntro("h1 span span"),
        {
          yPercent: 100,
        },
        {
          yPercent: 0,
          stagger: {
            amount: 0.5,
          },
          duration: 1.5,
        }
      )
      .fromTo(
        qIntro(".portfolio-video-carousel"),
        {
          xPercent: 15,
        },
        {
          xPercent: 0,
          duration: 1.25,
        },
        0
      )
      .fromTo(
        qIntro(".portfolio-more button"),
        {
          yPercent: 50,
          autoAlpha: 0,
        },
        {
          yPercent: 0,
          autoAlpha: 1,
          ease: "expo.inOut",
        },
        0.5
      );

    // timelines for each work
    gsap.utils.toArray(".work-box").forEach((box) => {
      // tl first appearence
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: box,
          scroller: ".scroll-section",
          start: "top center",
          once: true,
        },
        defaults: {
          duration: 0.75,
          ease: "expo.out",
        },
      });
      tl.fromTo(
        box.querySelector(".box-text h2 span"),
        {
          yPercent: 200,
        },
        {
          yPercent: 0,
          duration: 1.25,
        }
      )
        .fromTo(
          box.querySelector(".box-text p span"),
          {
            yPercent: 150,
          },
          {
            yPercent: 0,
          },
          0.25
        )
        .fromTo(
          box.querySelector(".box-text > a"),
          {
            autoAlpha: 0,
            yPercent: 50,
          },
          {
            autoAlpha: 1,
            yPercent: 0,
          },
          0.5
        );

      // tl mouse hover
      const imgBox = box.querySelector(".box-img");
      const img = imgBox.querySelector(".img");
      const text = imgBox.querySelector(".text");

      const callToAction = imgBox.querySelector(".text p span");
      const button = imgBox.querySelector(".text a");

      const hoverTl = gsap.timeline({
        paused: true,
      });

      hoverTl
        .fromTo(
          img,
          {
            scale: 1.25,
          },
          {
            scale: 1,
            duration: 0.5,
          }
        )
        .fromTo(
          text,
          {
            autoAlpha: 0,
          },
          {
            autoAlpha: 1,
            duration: 0.25,
            ease: "none",
          },
          0
        )
        .fromTo(
          callToAction,
          {
            yPercent: 150,
          },
          {
            yPercent: 0,
            duration: 0.75,
            ease: "expo.out",
          },
          0
        )
        .fromTo(
          button,
          {
            autoAlpha: 0,
            yPercent: 50,
          },
          {
            autoAlpha: 1,
            yPercent: 0,
            duration: 0.5,
            ease: "expo.out",
          },
          0.25
        );

      imgBox.addEventListener("mouseenter", () => {
        hoverTl.play();
      });
      imgBox.addEventListener("mouseleave", () => {
        hoverTl.reverse();
      });

      // move img on scroll
      ScrollTrigger.matchMedia({
        "(min-width: 1101px)": () => {
          gsap.set(img, {
            yPercent: 0,
          });
          const imgTl = gsap.timeline({
            scrollTrigger: {
              trigger: box,
              scroller: ".scroll-section",
              start: "top center",
              end: "bottom center",
              scrub: 1,
            },
          });
          imgTl.fromTo(
            imgBox,
            {
              yPercent: 10,
            },
            {
              yPercent: -10,
              ease: "none",
            }
          );
        },
        "(max-width: 1100px)": () => {
          gsap.set(imgBox, {
            clearProps: "transform",
          });
          const imgTl = gsap.timeline({
            scrollTrigger: {
              trigger: box,
              scroller: ".scroll-section",
              start: "top bottom",
              end: "bottom top",
              scrub: 1,
            },
          });
          imgTl.fromTo(
            img,
            {
              yPercent: -15,
            },
            {
              yPercent: 15,
              ease: "none",
            }
          );
        },
      });
    });

    // timeline for work-scroller
    const workScrollerContainer = document.querySelector(".work-scroller");
    ScrollTrigger.matchMedia({
      "(min-width: 575px)": function () {
        gsap.set(workScrollerContainer, {
          clearProps: "display",
        });
        const workScroller = workScrollerContainer.querySelectorAll("button");
        let proxy = { y: 0 },
          ySetter = gsap.quickSetter(workScroller, "y", "px"), // fast
          clamp = gsap.utils.clamp(-25, 25); // don't let y go beyond 20 degrees.
        const scrollTl = gsap.timeline({
          scrollTrigger: {
            trigger: workScrollerContainer,
            scroller: ".scroll-section",
            start: "center+=100 bottom",
            endTrigger: "footer",
            end: "top bottom",
            toggleActions: "play reverse play reverse",
            pin: true,
            onUpdate: (self) => {
              let y = clamp(self.getVelocity() / -300);
              // only do something if the skew is MORE severe. Remember, we're always tweening back to 0, so if the user slows their scrolling quickly, it's more natural to just let the tween handle that smoothly rather than jumping to the smaller skew.
              if (Math.abs(y) > Math.abs(proxy.y)) {
                proxy.y = y;
                gsap.to(proxy, {
                  y: 0,
                  duration: 0.4,
                  ease: "power3",
                  overwrite: true,
                  onUpdate: () => ySetter(proxy.y),
                });
              }
            },
          },
          defaults: {
            ease: "expo.inOut",
          },
        });
        scrollTl.fromTo(
          workScroller,
          {
            xPercent: 200,
            autoAlpha: 0,
          },
          {
            xPercent: 0,
            autoAlpha: 1,
          }
        );
      },
      "(max-width: 574px)": function () {
        gsap.set(workScrollerContainer, {
          display: "none",
        });
        gsap.set(document.querySelectorAll(".work-box > .box-img"), {
          xPercent: "-100px",
        });
      },
    });

    // timeline for video carousel
    setTimeout(() => {
      loopIt(document.querySelectorAll(".portfolio-video-carousel li"), {
        repeat: -1,
        speed: 0.75,
        reversed: false,
        paddingLeft: parseFloat(
          gsap.getProperty(
            document.querySelectorAll(".portfolio-video-carousel li")[0],
            "marginLeft",
            "px"
          )
        ),
      });
    }, mainTl.current.duration() * 1000 - 900);

    // // DON'T TOUCCHE GNIENTE DOPO QUA  // //

    // refresher for ScrollTrigger to work w/ LocomotiveScroll
    ScrollTrigger.addEventListener("refresh", () => scroll.current.update());
    ScrollTrigger.refresh();

    setTimeout(() => {
      scroll.current.update();
    }, 10);

    return () => {
      // stop observing
      observer1.disconnect();
      observer2.disconnect();
      // destroy Locomotive Scroll instace on page change
      scroll.current.destroy();
    };
  }, []);

  return (
    <>
      <Seo
        title="Portfolio"
        description="Scopri le mie esperienze e con chi ho collaborato."
      />
      <section ref={intro} id="work-0" className="portfolio-intro-container">
        <h1 sx={{ color: "primary" }}>
          <SplitText text="Le mie esperienze" />
        </h1>
        <ul className="portfolio-video-carousel">
          <li>
            <ReactPlayer
              url={scrs[0]}
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </li>
          <li>
            <ReactPlayer
              url={scrs[1]}
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </li>
          <li>
            <ReactPlayer
              url={scrs[2]}
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </li>
          <li>
            <ReactPlayer
              url={scrs[3]}
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </li>
          <li>
            <ReactPlayer
              url={scrs[4]}
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </li>
          <li>
            <ReactPlayer
              url={scrs[5]}
              playing={true}
              loop={true}
              muted={true}
              width="100%"
              height="100%"
            />
          </li>
        </ul>
        <div className="portfolio-more">
          <ArrowLink
            to="#work-1"
            text="Alcuni lavori in evidenza"
            scroller={scroll.current}
          />
        </div>
      </section>
      <section ref={portfolio} id="works" className="portfolio-container">
        <div className="work-scroller">
          <ArrowLink
            to="#work-0"
            text="Torna su"
            direction="up"
            mirrored={true}
            scroller={scroll.current}
          />
          <br></br>
          <ArrowLink
            to="#collaborazioni"
            text="Vai alle collaborazioni"
            direction="bottom"
            mirrored={true}
            scroller={scroll.current}
          />
        </div>

        <div id="work-extra-2" className="work-box">
          <div className="box-img">
            <StaticImage
              src="../../images/cover-avavav-2023.jpg"
              alt="Cover per la sfilata di moda di AVAVAV alla MFW"
              className="img shadow-black"
            />
            <div className="text">
              <p>
                <span>Guarda le sfilate</span>
              </p>
              <TransButton
                to="/portfolio/avavav"
                title="Per la pagina dedicata al progetto AVAVAV"
                type="menu"
              >
                Visita la pagina dedicata
              </TransButton>
            </div>
          </div>
          <div className="box-text">
            <h2>
              <span>AVAVAV - MFW 2022 & 2023</span>
            </h2>
            <p>
              <span>
                Riprese video Milano Fashion Week 2022 & 2023 per la sfilata di
                AVAVAV.
              </span>
            </p>
            <TransButton
              to="/portfolio/avavav"
              title="Per la pagina dedicata al progetto AVAVAV"
              type="decorative"
            >
              Il progetto nel dettaglio
            </TransButton>
          </div>
        </div>

        {/* <div id='work-extra-1' className='work-box'>
          <div className='box-img'>
            <StaticImage
              src='../../images/cover-avavav.jpg'
              alt='Cover per la sfilata di moda di AVAVAV alla MFW'
              className='img shadow-black'
            />
            <div className='text'>
              <p>
                <span>Guarda la sfilata</span>
              </p>
              <TransButton
                to='/portfolio/avavav-2022'
                title='Per la pagina dedicata al progetto AVAVAV'
                type='menu'
              >
                Visita la pagina dedicata
              </TransButton>
            </div>
          </div>
          <div className='box-text'>
            <h2>
              <span>AVAVAV - MFW 2022</span>
            </h2>
            <p>
              <span>
                Ripresa video Milano Fashion Week 2022 per la sfilata di AVAVAV.
              </span>
            </p>
            <TransButton
              to='/portfolio/avavav-2022'
              title='Per la pagina dedicata al progetto AVAVAV'
              type='decorative'
            >
              Il progetto nel dettaglio
            </TransButton>
          </div>
        </div> */}

        <div id="work-1" className="work-box">
          <div className="box-img">
            <StaticImage
              src="../../images/cover-ercole-tebe.jpg"
              alt="Cover per il lavoro Ercole in Tebe al Teatro della Pergola"
              className="img shadow-black"
            />
            <div className="text">
              <p>
                <span>Guarda lo spettacolo</span>
              </p>
              <TransButton
                to="/portfolio/ercole-tebe"
                title="Per la pagina dedicata al progetto Ercole in Tebe"
                type="menu"
              >
                Visita la pagina dedicata
              </TransButton>
            </div>
          </div>
          <div className="box-text">
            <h2>
              <span>Ercole in Tebe</span>
            </h2>
            <p>
              <span>
                Riprese audio e video dell'opera <em>Ercole in Tebe</em>,
                eseguita dopo trecentosessant'anni dall'inaugurazione del Teatro
                della Pergola, a Firenze.
              </span>
            </p>
            <TransButton
              to="/portfolio/ercole-tebe"
              title="Per la pagina dedicata al progetto Ercole in Tebe"
              type="decorative"
            >
              Il progetto nel dettaglio
            </TransButton>
          </div>
        </div>

        <div id="work-2" className="work-box">
          <div className="box-img">
            <StaticImage
              src="../../images/cover-oxford.jpg"
              alt="Cover per il lavoro Non solo Muse per Oxford University"
              className="img shadow-black"
            />
            <div className="text">
              <p>
                <span>Guarda le intervsite</span>
              </p>
              <TransButton
                to="/portfolio/non-solo-muse"
                title="Per la pagina dedicata al progetto Ercole in Tebe"
                type="menu"
              >
                Visita la pagina dedicata
              </TransButton>
            </div>
          </div>
          <div className="box-text">
            <h2>
              <span>Non Solo Muse</span>
            </h2>
            <p>
              <span>
                Interviste alle poetesse del panorama contemporaneo italiano per{" "}
                <em>Oxford University</em>.
              </span>
            </p>
            <TransButton
              to="/portfolio/non-solo-muse"
              title="Per la pagina dedicata al progetto Ercole in Tebe"
              type="decorative"
            >
              Il progetto nel dettaglio
            </TransButton>
          </div>
        </div>

        <div id="work-3" className="work-box">
          <div className="box-img">
            <StaticImage
              src="../../images/cover-marco-angioloni.jpg"
              alt="Cover per il lavoro Marco Angioloni"
              className="img shadow-black"
            />
            <div className="text">
              <p>
                <span>Guarda i videoclip</span>
              </p>
              <TransButton
                to="/portfolio/marco-angioloni"
                title="Per la pagina dedicata al progetto Marco Angioloni"
                type="menu"
              >
                Visita la pagina dedicata
              </TransButton>
            </div>
          </div>
          <div className="box-text">
            <h2>
              <span>Marco Angioloni</span>
            </h2>
            <p>
              <span>
                Realizzazione di 2 video musicali ed un teaser trailer per
                l'uscita dell'album barocco di <em>Marco Angioloni</em>.
              </span>
            </p>
            <TransButton
              to="/portfolio/marco-angioloni"
              title="Per la pagina dedicata al progetto Marco Angioloni"
              type="decorative"
            >
              Il progetto nel dettaglio
            </TransButton>
          </div>
        </div>

        <div id="work-4" className="work-box">
          <div className="box-img">
            <StaticImage
              src="../../images/cover-jubilate.jpg"
              alt="Cover per il lavoro Jubilate Deo"
              className="img shadow-black"
            />
            <div className="text">
              <p>
                <span>Guarda il concerto</span>
              </p>
              <TransButton
                to="/portfolio/jubilate-deo"
                title="Per la pagina dedicata al progetto Jubilate Deo"
                type="menu"
              >
                Visita la pagina dedicata
              </TransButton>
            </div>
          </div>
          <div className="box-text">
            <h2>
              <span>Jubilate Deo</span>
            </h2>
            <p>
              <span>
                Riprese video ed audio del concerto svolto nella Cappella
                Musicale della Basilica di San Lorenzo per{" "}
                <em>Johannes Gutenberg University Mainz</em> e l'
                <em>Università di Firenze</em>.
              </span>
            </p>
            <TransButton
              to="/portfolio/jubilate-deo"
              title="Per la pagina dedicata al progetto Jubilate Deo"
              type="decorative"
            >
              Il progetto nel dettaglio
            </TransButton>
          </div>
        </div>

        <div id="work-5" className="work-box">
          <div className="box-img">
            <StaticImage
              src="../../images/cover-villa-maria.jpg"
              alt="Cover per il lavoro Villa Maria Paola"
              className="img shadow-black"
            />
            <div className="text">
              <p>
                <span>Guarda le riprese</span>
              </p>
              <TransButton
                to="/portfolio/villa-maria-paola"
                title="Per la pagina dedicata al progetto Villa Maria Paola"
                type="menu"
              >
                Visita la pagina dedicata
              </TransButton>
            </div>
          </div>
          <div className="box-text">
            <h2>
              <span>Villa Maria Paola</span>
            </h2>
            <p>
              <span>
                Riprese e montaggio video per <em>Villa Maria Paola</em>, centro
                medico specializzato a Firenze.
              </span>
            </p>
            <TransButton
              to="/portfolio/villa-maria-paola"
              title="Per la pagina dedicata al progetto Villa Maria Paola"
              type="decorative"
            >
              Il progetto nel dettaglio
            </TransButton>
          </div>
        </div>

        <div id="work-6" className="work-box">
          <div className="box-img">
            <StaticImage
              src="../../images/cover-ultimi-medici.jpg"
              alt="Cover per il lavoro La Musica degli Ultimi Medici"
              className="img shadow-black"
            />
            <div className="text">
              <p>
                <span>Guarda l'evento</span>
              </p>
              <TransButton
                to="/portfolio/musica-ultimi-medici"
                title="Per la pagina dedicata al progetto La Musica degli Ultimi Medici"
                type="menu"
              >
                Visita la pagina dedicata
              </TransButton>
            </div>
          </div>
          <div className="box-text">
            <h2>
              <span>La Musica degli Ultimi Medici</span>
            </h2>
            <p>
              <span>
                Riprese video e registrazione audio del concerto trasmesso in
                streaming dell'ensemble <em>I Musici del Gran Principe</em>.
              </span>
            </p>
            <TransButton
              to="/portfolio/musica-ultimi-medici"
              title="Per la pagina dedicata al progetto La Musica degli Ultimi Medici"
              type="decorative"
            >
              Il progetto nel dettaglio
            </TransButton>
          </div>
        </div>

        <div id="work-7" className="work-box">
          <div className="box-img">
            <StaticImage
              src="../../images/cover-museo-medici.jpg"
              alt="Cover per il servizio fotografico al Museo de Medici"
              className="img shadow-black"
            />
            <div className="text">
              <p>
                <span>Guarda le foto</span>
              </p>
              <TransButton
                to="/portfolio/museo-medici"
                title="Per la pagina dedicata al progetto per il Museo de Medici"
                type="menu"
              >
                Visita la pagina dedicata
              </TransButton>
            </div>
          </div>
          <div className="box-text">
            <h2>
              <span>Museo de Medici</span>
            </h2>
            <p>
              <span>
                Servizio fotografico per il <em>Museo de Medici</em>, nel
                palazzo di Sforza Almeni a Firenze.
              </span>
            </p>
            <TransButton
              to="/portfolio/museo-medici"
              title="Per la pagina dedicata al progetto per il Museo de Medici"
              type="decorative"
            >
              Il progetto nel dettaglio
            </TransButton>
          </div>
        </div>

        <div id="work-8" className="work-box">
          <div className="box-img">
            <StaticImage
              src="../../images/cover-palazzo-vecchio.jpg"
              alt="Cover per il lavoro a Palazzo Vecchio"
              className="img shadow-black"
            />
            <div className="text">
              <p>
                <span>Guarda le foto</span>
              </p>
              <TransButton
                to="/portfolio/palazzo-vecchio"
                title="Per la pagina dedicata al progetto per Palazzo Vecchio"
                type="menu"
              >
                Visita la pagina dedicata
              </TransButton>
            </div>
          </div>
          <div className="box-text">
            <h2>
              <span>Palazzo Vecchio</span>
            </h2>
            <p>
              <span>
                Servizio fotografico al <em>Salone dei 500</em> a Firenze per i
                tre secoli dalla morte della granduchessa Marguerite Louise
                d'Orléans.
              </span>
            </p>
            <TransButton
              to="/portfolio/palazzo-vecchio"
              title="Per la pagina dedicata al progetto per Palazzo Vecchio"
              type="decorative"
            >
              Il progetto nel dettaglio
            </TransButton>
          </div>
        </div>
      </section>
      <section ref={logo} id="collaborazioni" className="portfolio-logo">
        <h1
          sx={{
            em: {
              color: "primary",
            },
          }}
        >
          <span>
            Chi ha <em>creduto</em> nel mio <em>progetto</em>
          </span>
        </h1>
        <ul className="logo-container">
          <li>
            <StaticImage
              src="../../images/logo/logo-abb.png"
              alt="Logo della ABB"
              className="logo logo-medium"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-rai.png"
              alt="Logo della RAI"
              className="logo logo-smaller"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-oxford.png"
              alt="Logo di Oxford University"
              className="logo"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-toscana.png"
              alt="Logo della Regione Toscana"
              className="logo"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-firenze.png"
              alt="Logo del comune di Firenze"
              className="logo logo-medium"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-avavav.webp"
              alt="Logo AVAVAV"
              className="logo"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-giunti.png"
              alt="Logo del comune di Giunti Editore"
              className="logo"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-medici.png"
              alt="Logo del museo de Medici"
              className="logo"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-uni-gutenberg.png"
              alt="Logo di Gutenberg university"
              className="logo"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-uni-firenze.png"
              alt="Logo dell università di Firenze"
              className="logo"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-bongiovanni.png"
              alt="Logo Bongiovanni"
              className="logo"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-teletruria.png"
              alt="Logo Teletruria"
              className="logo logo-smaller"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-outhere.png"
              alt="Logo Outhere Music"
              className="logo"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-mfw.jpeg"
              alt="Logo MFW 2022"
              className="logo logo-smaller"
            />
          </li>
          <li>
            <StaticImage
              src="../../images/logo/logo-arcana.png"
              alt="Logo Arcana"
              className="logo logo-smaller"
            />
          </li>
        </ul>
        {/* <div className='engagement-container' sx={{ bg: 'secondary' }}>
          <p className='title' sx={{ color: 'primary' }}>
            <SplitText text='Iniziamo subito, non aspettare' />
          </p>
          <p></p>
        </div> */}
      </section>
    </>
  );
};

export default Portfolio;
